//
// File manager.scss
//

// file manager

.filemanager-sidebar {
  @media (min-width: 1200px) {
    min-width: 230px;
    max-width: 230px;
  }

  @media (min-width: 1366px) {
    min-width: 280px;
    max-width: 280px;
  }
}

.categories-list {
  padding: 4px 0;
  li {
    a {
      display: block;
      padding: 8px 12px;
      color: $body-color;
      font-weight: $fw-medium;
    }

    &.active {
      a {
        color: $primary;
      }
    }

    ul {
      padding-left: 16px;
      li {
        a {
          padding: 4px 12px;
          color: $text-muted;
          font-size: 13px;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
}
