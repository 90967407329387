.block-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: block;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    background: #fff;
    padding: 14px 35px;
    border-radius: 12px;
    font-size: 15px;
    color: #000;
    i {
      margin-right: 5px;
    }
  }
}
